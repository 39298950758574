import { render, staticRenderFns } from "./servicecarousel.vue?vue&type=template&id=59a82d72&scoped=true&"
import script from "./servicecarousel.vue?vue&type=script&lang=js&"
export * from "./servicecarousel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59a82d72",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Rates: require('/app/components/rates/index.vue').default,Swiper: require('/app/components/swiper/swiper.vue').default})
