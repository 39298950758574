
export default {
  data() {
    return {
      index  : 0,
    }
  },
  computed : {
    visible() {
      return this.index === this.$parent.index;
    },
    dir() {
      return this.$parent.slideDirection;
    },
  }
}
