
import Tooltip from "@/components/tooltip";
export default {
  name: "newproductswiper",
  components: {Tooltip},
  data() {
    return {
      products: []
    }
  },
  computed: {
    city: {
      get() {
        return this.$store.state.city.slug
      },
      set(value) {
        localStorage.setItem("city", value)
        this.$cookies.set('city', value);
        this.$store.commit('city/setCitySlug', value)
      },
    }
  },
  async mounted() {
    var products = await this.$http.$get(`/tire/listing/new-categories/michelin`)
    this.products = products.data.tireCategories
  },
  methods: {
    changeRoute(brand, category) {
      if (this.city) {
        this.$router.push(this.localePath({name: 'products', params: {brand: brand}, query: {city: this.city,category: category}}))
      } else {
        this.$router.push(this.localePath({name: 'products', params: {brand: brand}, query: {category: category}}))
      }
    }
  }
}
