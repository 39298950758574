
export default {
  name: "brandmegabar",
  methods: {
    closeSearchBar() {
      document.getElementById('myNav1').style.width = "0%";
    },
    setFilter(brand) {
      if (brand) {
        this.filter.brand = brand
      }
      this.$store.commit('megafilter/setFilterBrand', this.filter.brand)
      this.$nuxt.$emit('setStepComponent', 3)
    },
    setStep(key) {
      this.$nuxt.$emit('setStepComponent', key)
    }
  },
  data() {
    return {
      brands: [],
      beforeFilter: '',
      filter: {
        brand: {
          name: ''
        }
      }
    }
  },
  async mounted() {
    if (this.$store.state.megafilter.filter.city.name) {
      this.beforeFilter = this.$store.state.megafilter.filter.city.name
    }
    if (this.$store.state.megafilter.filters.length > 0) {
      if (Object.keys(this.$store.state.megafilter.filters.brands).length > 0) {
        this.brands = this.$store.state.megafilter.filters.brands
      } else {
        var brands = await this.$http.$get(`/tire/listing/brand-with-categories`)
        if (brands.status) {
          this.brands = brands.data.brands
          this.$store.commit('megafilter/setFiltersBrand', this.brands)
        }
      }
    } else {
      var brands = await this.$http.$get(`/tire/listing/brand-with-categories`)
      if (brands.status) {
        this.brands = brands.data.brands
        this.$store.commit('megafilter/setFiltersBrand', this.brands)
      }
    }
  },
}
