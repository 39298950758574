
export default {
  data () {
    return {
      index : 0,
      slides : [],
      slideDirection: '',
    }
  },
  props: {
    buttons: {
      default: true,
      type: Boolean
    },
    time: {
      default: 8000,
      type: Number
    }
  },
  computed: {
    slidesLength() {
      return this.slides.length;
    }
  },
  mounted(){
    setInterval(this.next, this.time)
    this.slides = this.$children;
    this.slides.map( (slide,index) => {
      slide.index = index;
    });
  },
  methods: {
    next(){
      this.index++;
      if(this.index >= this.slidesLength){
        this.index = 0;
      }
      this.slideDirection = 'slide-right';
    },
    prev(){
      this.index--;
      if(this.index < 0){
        this.index = this.slidesLength - 1;
      }
      this.slideDirection = 'slide-left';
    },
    checkSlide(event){
      if(event.keyCode === 39){
        this.next();
      }else if (event.keyCode === 37){
        this.prev();
      }else {
        return;
      }
    },
  }
}
