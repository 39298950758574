
import Foot from "@/layouts/foot";
import Campaigncarousel from "@/components/campaigns/campaigncarousel";
import Servicecarousel from "@/components/services/servicecarousel";
import Feedbacks from "@/components/feedbacks/index";
import campaignswiper from "@/components/swiper/campaignswiper";
import Rates from "@/components/rates";
import Newproductswiper from "@/components/products/newproductswiper";
import Brandmegabar from "@/components/search/brandmegabar";
import Searchmegamenu from "@/components/search/searchmegamenu";
import Subscribe from "@/components/subscribes/subscribe";
import howdoes from "@/components/home/howdoes";
import bestsellers from "@/components/home/bestsellers";
import Bestsellers from "@/components/home/bestsellers";
export default {
  name: 'IndexPage',
  head() {
    return {
      title: this.$store.state.config.items.meta_title,
      meta: [
        { hid: 'keywords', name: 'keywords', content: this.$store.state.config.items.meta_keywords },
        { hid: 'description', name: 'description', content: this.$store.state.config.items.meta_description },
      ],

    }
  },
  components: {
    Bestsellers,
    Searchmegamenu,
    Newproductswiper, Rates, Servicecarousel, Campaigncarousel, Foot, campaignswiper, Feedbacks,Subscribe,howdoes},
  computed: {
    city: {
      get() {
        if (this.$cookies.get("city")) {
          return this.$cookies.get("city")
        } else if (localStorage.getItem("city")) {
          return localStorage.getItem("city")
        } else if (this.$store.state.city.slug) {
          return this.$store.state.city.slug
        }
      },
      set(value) {
        localStorage.setItem("city", value)
        this.$cookies.set('city', value);
        this.$store.commit('city/setCitySlug', value)
      }
    },
  },
  fetchKey: 'index',
  data() {
    return {
      brands: [],
      config: {},
      slider: 0,
      sliders: [],
      interval: 0,
      megabar: false,
    }
  },
  mounted() {
    //this.interval = setInterval(this.next, 5000)
    if (this.$store.state.brands.list.length > 0) {
      this.brands = this.$store.state.brands.list
    } else {
      this.$http.$get(`/tire/listing/brand-with-categories`).then((brands) => {
        if (brands.code === 1000) {
          this.brands = brands.data.brands
          this.$store.commit('brands/setList', this.brands)
        }
      })
    }
  },
  async fetch() {
    var sliders = await this.$http.$get(`/sliders`)
    this.sliders = sliders.data.sliders
    if (Object.keys(this.$store.state.config.items).length > 0) {
      this.config =  this.$store.state.config.items
    } else {
      var config = await this.$http.$get('/home-page/app-settings')
      this.$store.commit('config/setItem', config.data.appSettings)
      this.config = this.$store.state.config.items
    }
  },
  methods: {
    next() {
      this.slider = this.slider + 1
      if (this.slider === this.sliders.length) {
        this.slider = 0
      }
      clearInterval(this.interval)
      this.interval = setInterval(this.next, 5000)
    },
    prev() {
      this.slider = this.slider - 1
      if (this.slider < 0) {
        this.slider = this.sliders.length - 1
      }
      clearInterval(this.interval)
      this.interval = setInterval(this.next, 5000)
    },
    clickDots(key) {
      this.slider = key
      clearInterval(this.interval)
      this.interval = setInterval(this.next, 5000)
    },
    openSearchBar() {
      setTimeout(function () {
        document.getElementById("myNav1").style.width = "100%";
      })
    },
  }
}
