
import swiper from "@/components/swiper/swiper";
export default {
  name: "campaignswiper",
  components: {
    swiper
  },
  async mounted() {
    if (this.camp.length > 0) {

    } else {
      var campaigns = await this.$http.$get('/cms/posts/campaign/by-type-slug')
      this.campaigns = campaigns.data.cmsPosts.items
    }
  },
  props: {
    camp: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      campaigns: [],
      responsive: [
        {
          maxWidth: 1280,
          slidesPerPage: 3,
        },
        {
          maxWidth: 1024,
          slidesPerPage: 2,
        },
        {
          maxWidth: 767,
          slidesPerPage: 1
        }
      ]
    }
  }
}
