import { render, staticRenderFns } from "./newproductswiper.vue?vue&type=template&id=186d7eb8&scoped=true&"
import script from "./newproductswiper.vue?vue&type=script&lang=js&"
export * from "./newproductswiper.vue?vue&type=script&lang=js&"
import style0 from "./newproductswiper.vue?vue&type=style&index=0&id=186d7eb8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "186d7eb8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Swiper: require('/app/components/swiper/swiper.vue').default})
