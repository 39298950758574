
import slide from "@/components/carousel/slide.vue"
import carousel from "@/components/carousel/carousel";
export default {
  name: "campaigncarousel",
  components: {
    carousel,
    slide
  }
}
