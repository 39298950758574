
import slide from "@/components/carousel/slide.vue"
import carousel from "@/components/carousel/carousel";
import Rates from "@/components/rates";

export default {
  name: "servicecarousel",
  components: {
    Rates,
    carousel,
    slide
  },
  data() {
    return {
      services: []
    }
  },
  async fetch() {
    var services = await this.$http.$get(`/home-page/branches/best-branches`)
    this.services = services.data.branches
  },
  methods: {
    seeMore() {
      if (this.city) {
        this.$router.push(this.localePath({name:'branches', query: {city: this.city}}))
      } else {
        this.$router.push(this.localePath({name:'branches'}))
      }
    }
  }
}
