
import swiper from 'swiper'

export default {
  name: "campaignswiper",
  props: {
    id: {
      type:Number,
      default: 1
    },
    per: {
      type: Number,
      default: 3
    },
    buttons: {
      type: Boolean,
      default: true
    },
    loop: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    // const swiper = swiper();
    // return {
    //   swiper,
    // };
  },
  data() {
    return {
      options: {
        slidesPerView: this.per,
        loop: this.loop === false ? false : (this.items.length < this.per ? false : true),
        navigation: {
          nextEl: '.btn-next-' + this.id,
          prevEl: '.btn-prev-' + this.id
        },
        breakpoints: {
          1024: {
            slidesPerView: this.per,
            spaceBetween: 30
          },
          1000: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10
          }
        }
      }
    }
  }
}
