import { render, staticRenderFns } from "./campaigncarousel.vue?vue&type=template&id=09b1cfeb&scoped=true&"
import script from "./campaigncarousel.vue?vue&type=script&lang=js&"
export * from "./campaigncarousel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09b1cfeb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Carousel: require('/app/components/carousel/carousel.vue').default})
