import { render, staticRenderFns } from "./subscribe.vue?vue&type=template&id=5cb3c183&scoped=true&"
var script = {}
import style0 from "./subscribe.vue?vue&type=style&index=0&id=5cb3c183&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cb3c183",
  null
  
)

export default component.exports