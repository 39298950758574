
import productcard from "@/components/product/productcard";
export default {
  name: "bestsellers",
  methods: {
    refresh() {
      driverreviews.isRefreshing= false
      driverreviews.refreshWidgets();
    },
  },
  data(){
    return{
      products: [],
    }
  },
  components: {productcard},
  computed: {
    city: {
      get() {
        return this.$store.state.city.slug
      },
    }
  },
  watch: {
    '$route': '$fetch',
    products() {
      var _this = this
      if (this.products.length > 0) {
        setTimeout(function () {
          _this.refresh()
        },100)
      }
    }
  },
  async fetch() {
    try {
      var response = await this.$axios.get(`/tire/products/best-sellers`)
      if (response.data.status) {
        this.products=response.data.data.bestSellers
        this.refresh()
      }
    } catch (error) {
    }
  },
}
