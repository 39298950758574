
export default {
  name: "tooltip",
  props: {
    message: {
      default: "",
      type: String
    },
    placeholder: {
      default: "",
      type: String
    },
    url: {
      default: '',
      type: String
    }
  }
}
