
import Rates from "@/components/rates";
export default {
  name: "index",
  components: {Rates},
  data() {
    return {
      feedbacks: []
    }
  },
  async mounted() {
    var feedbacks = await this.$http.$get('/home-page/feedbacks')
    this.feedbacks = feedbacks.data.tireFeedbacks
  }
}
