
export default {
  name: "productcard",
  props:{
    product: {
      type: Object,
      default: {}
    },
    barcode: {
      type: String,
      default: ''
    },
  },
  computed: {
    city: {
      get() {
        return this.$store.state.city.slug
      },
    }
  },
}
