
export default {
  name: "rates",
  data() {
    return {};
  },
  props: {
    star: {
      type: [Number,String],
      default: 0,
    },
    rate: {
      type: [Number,String],
      default: 0,
    },
    isList: {
      type: [Boolean],
      default: false
    },
    activeColor: {
      type: [String],
      default: '#efc518'
    }
  },
}
